import React from "react";
import './ListarProdutos.css';
import FFRequest from "../../Controller/Auxiliar/FFRequest";
import ProdutoDao from "../../Dao/ProdutoDao";
import FFString from "../../Controller/Auxiliar/FFString";


class ListarProdutos extends React.Component<any> {
    state: any = {
        cardapio: {
            produtos: []
        }
    }
    constructor(props: any) {
        super(props);
        this.initPropsAsync();
    }

    async initPropsAsync() {
        const pdvHash = FFRequest.findGetParameter('cardapio') + "";
        var prod = new ProdutoDao();
        var produtos = await prod.getListProdutos(pdvHash, this.props.location.state.categoria.id_categoria, this.props.location.state.dadosEmpresa);
        if (produtos.sucesso) {
            this.setState((state: any) => {
                state.cardapio.produtos = produtos.produtos
                return state;
            });
        }
    }
    listaProdutos() {
        var componentes: Array<any> = [];
        var cont = 0;
        this.state.cardapio.produtos.forEach((element: any) => {
            componentes.push(
                <div key={cont} className="col-12" >
                    <div className="row">
                        <div className="produtos col-3" >
                            <div className="imgProdutos">
                                <img src={element.imagem_capa} className="img-fluid" />
                            </div>
                        </div>
                        <div className="nomeProduto col-6">
                            {element.descricao_nao_fiscal}
                            <div className="descricaoCompleta">
                                {element.informacoes_complementares}
                            </div>
                        </div>
                        <div className=" valorUnitario col-3">
                            {FFString.formataMoeda(element.valor_unitario_nao_fiscal)}
                        </div>
                    </div>
                    <hr className="linha" />
                </div>
            );
            cont++;
        })
        return (
            <div className="container-fluid">
                <div className="row" >
                    {componentes}
                </div></div>
        );
    }

    render() {
        if (this.state.cardapio.produtos.length > 0)
            return this.listaProdutos();
        else
            return (<div />);
    }
}
export default ListarProdutos;