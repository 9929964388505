import axios from "axios";
import Config from "../Config";

class EmpresaDao {
    public async getDadosEmpresa(id_empresa: number) {
        var dadosEmpresa: any = await axios
            .post(Config.urlRestApi + "Empresa/GetDadosEmpresa.php", {
                'id_empresa': id_empresa
            })
            .catch((error) => {
                alert("Houve uma falha ao buscar os dados da empresa! Verifique se o seu dispositivo está conectado a internet!")
            })
        return dadosEmpresa.data;
    }
    public async getDadosEmpresaByPdvHash(pdvHash: string) {
        var dadosEmpresa: any = await axios
            .post(Config.urlRestApi + "Empresa/GetDadosEmpresa.php", {
                'pdvHash': pdvHash
            })
            .catch((error) => {
                alert("Houve uma falha ao buscar os dados da empresa! Verifique se o seu dispositivo está conectado a internet!")
            })
        return dadosEmpresa.data;
    }
}

export default EmpresaDao;