import React from "react";
import { Refreshable } from 'react-router-refreshable';
import { Route, Switch, withRouter } from 'react-router-dom';
import ComandaConsumo from "../Consumo/ComandaConsumo";
import CardapioDigital from "../CardapioDigital/CardapioDigital";

class MainRouter extends React.Component<any> {
    render() {
        return (
            <Refreshable>
                <Switch>
                    <Route  {...this.props} path="/ComandaConsumo" component={ComandaConsumo} />
                    <Route {...this.props} path="/CardapioDigital" component={CardapioDigital} />
                </Switch>
            </Refreshable>
        );
    }
}
export default withRouter(MainRouter);