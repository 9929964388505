class Config {

    static firebaseConfig = {
        apiKey: "AIzaSyBs6EPPrGF5KciVoLjgb748Wt4NiwYsetk",
        authDomain: "ffatende-782bf.firebaseapp.com",
        projectId: "ffatende-782bf",
        storageBucket: "ffatende-782bf.appspot.com",
        messagingSenderId: "347009031589",
        appId: "1:347009031589:web:6064902cadb27320399c4c",
        measurementId: "G-NLFQ82K1C1"
    };
    static urlRestApi = "https://teste.ffadmin.com.br/WebApi/public/RestApi/";
}
export default Config;