class FFString {
    public static formataMoeda(moeda: number) {
        if (moeda == null || moeda == undefined)
            moeda = 0;
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(moeda)
    }

    public static pad(pad: string, str: string, padLeft: boolean) {
        if (typeof str === 'undefined')
            return pad;
        if (padLeft) {
            return (pad + str).slice(-pad.length);
        } else {
            return (str + pad).substring(0, pad.length);
        }
    }
}
export default FFString;