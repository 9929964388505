import React from "react";
import FFRequest from "../../Controller/Auxiliar/FFRequest";
import Comanda from "../Comanda/Comanda";
class ComandaConsumo extends React.Component<any> {

    mostraItens = true;
    mensagemErro: String = "";
    componentWillMount() {
        if (FFRequest.findGetParameter('comanda') == null || FFRequest.findGetParameter('comanda') == null) {
            this.setState(() => {
                this.mostraItens = false;
                this.mensagemErro = 'Código da comanda não informado!'
            });
        }
    }

    render() {
        return (
            <div className="h-100">
                {this.mostraItens ?
                    <Comanda />
                    :
                    <h6>{this.mensagemErro}</h6>}
            </div>);
    }

}
export default ComandaConsumo;