import React from "react";
import './ListarCategorias.css';
import CategoriaDao from "../../Dao/CategoriaDao";
import { Link } from "react-router-dom";
import FFRequest from "../../Controller/Auxiliar/FFRequest";

class ListarCategorias extends React.Component<any> {

    state: any = {
        cardapio: {
            categorias: []
        }
    }
    constructor(props: any) {
        super(props);
       this.initPropsAsync();
    }

    async initPropsAsync() {
        const pdvHash = FFRequest.findGetParameter('cardapio') + "";
        var cat = new CategoriaDao();
        var categorias = await cat.getListCategorias(pdvHash,this.props.location.state);
        if (categorias.sucesso) {
            this.setState((state: any) => {
                state.cardapio.categorias = categorias.categorias
                return state;
            });
        }
    }

    listaCategorias() {
        var componentes: Array<any> = [];
        var cont = 0;
        this.state.cardapio.categorias.forEach((element: any) => {
            componentes.push(
                <div key={cont} className="col-4 col-sm-3 col-md-2 itemCategoria" >
                    <Link to={{ pathname: '/CardapioDigital/listarProdutos', search: window.location.search, state: { categoria: element ,dadosEmpresa:this.props.location.state} }}  style={{textDecoration:'none'}}>
                        <div className="conteudoDiv row" style={{ backgroundColor: this.props.location.state.primary_color_ffatende }} >
                            <div className="imgCategoria">
                                <img src={element.imagem_capa} className="img-fluid" />
                            </div>
                            <div className="col-12 categoriaTitle">
                                {element.descricao}
                            </div>
                        </div>
                    </Link>
                </div>
            );
            cont++;
        })
        return (
            <div className="container-fluid">
                <div className="row" >
                    {componentes}
                </div></div>
        );
    }

    render() {
        if (this.state.cardapio.categorias.length > 0)
            return this.listaCategorias();
        else
            return (<div />);
    }
}
export default ListarCategorias;