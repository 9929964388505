import axios from "axios";
import Config from "../Config";

class Categoriadao {
    public async getListCategorias(token: String, dados_empresa: any) {
        var dadosCategoria: any = await axios
            .post(Config.urlRestApi + "Produtos/ListarCategoriasProduto.php", {
                'cardapioDigital': true,
                'id_empresa': dados_empresa.id_empresa,
                'cnpj_empresa': dados_empresa.cnpj,
                'token': token
            })
            .catch((error) => {
                alert("Houve uma falha ao buscar os dados da categoria! Verifique se o seu dispositivo está conectado a internet!")
            })
        return dadosCategoria.data;
    }

}

export default Categoriadao;