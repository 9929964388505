import React from 'react';
import ReactDOM from 'react-dom/client';
import MainRouter from './View/Main/MainRouter';
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.Fragment>
    <BrowserRouter basename="/FFAtende">
      <MainRouter />
    </BrowserRouter>
  </React.Fragment>
);


