import React from "react";
import './CardapioDigital.css';
import FFRequest from "../../Controller/Auxiliar/FFRequest";
import EmpresaDao from "../../Dao/EmpresaDao";
import { Redirect, Route, Switch } from "react-router-dom";
import ListarCategorias from "../ListarCategorias/ListarCategorias";
import ListarProdutos from "../ListarProdutos/ListarProdutos";


class CardapioDigital extends React.Component<any> {

    state: any = {}

    constructor(props: any) {
        super(props);
        this.initialState();
    }

    async initialState() {
        const pdvHash = FFRequest.findGetParameter('cardapio') + "";
        var emp = new EmpresaDao();
        var dadosEmpresa = await emp.getDadosEmpresaByPdvHash(pdvHash)
        this.setState((state: any) => {
            state.dadosEmpresa = dadosEmpresa
            return state;
        });

    }


    render(): React.ReactNode {
        if (this.state.dadosEmpresa && !this.state.dadosEmpresa.vazio) {
            return (
                <div className="container-fluid h-100 d-flex flex-column" >
                    <div className="row justify-content-center">
                        <img className="imageBar" src={this.state.dadosEmpresa.logo}></img>
                    </div>
                    <div className="row justify-content-center headerBarCardapio" style={{ backgroundColor: this.state.dadosEmpresa.secondary_color_ffatende }}>
                        <div className="col-12 text-center text-white flex-column justify-content-center d-flex ">
                            Cardápio
                        </div>
                    </div>
                    <div className="flex-grow-1 rowItensCategorias">
                        <Switch>
                            <Redirect exact from="/CardapioDigital" to={{ pathname: '/CardapioDigital/listarCategorias', search: window.location.search, state: this.state.dadosEmpresa }} />
                            <Route path='/CardapioDigital/listarCategorias' >
                                <ListarCategorias {...this.props} />
                            </Route>
                            <Route path='/CardapioDigital/listarProdutos'  >
                                <ListarProdutos {...this.props} />
                            </Route>
                        </Switch>
                    </div>
                    <div className="row justify-content-end rodapeDiv" style={{ backgroundColor: this.state.dadosEmpresa.secondary_color_ffatende }}>
                        <div className="col-4 text-center itemRodape">
A
                        </div>
                        <div className="col-4  text-center  itemRodape">
A
                        </div>
                        <div className="col-4 text-center itemRodape">
A
                        </div>
                    </div>
                </div>);
        }
        else {
            return <div>Cardápio não encontrado</div>;
        }
    }
}
export default CardapioDigital;