import React from "react";
import { FFAtendeFirebaseDb } from "../../Controller/FFFirebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import FFRequest from "../../Controller/Auxiliar/FFRequest";
import FFString from "../../Controller/Auxiliar/FFString";

import './Comanda.css';
import axios from "axios";
import Config from "../../Config";
import EmpresaDao from "../../Dao/EmpresaDao";


class Comanda extends React.Component<any> {
    state: any = {
        comanda: {
            dados_empresa: {},
            itens_comanda: []
        }
    }

    constructor(props: any) {
        super(props);

    }

    componentWillMount() {
        this.componentWillMountAsync();

    }

    async componentWillMountAsync() {
        const identificador = FFRequest.findGetParameter('comanda') + "";
        const idEmpresa = parseInt(identificador.substring(4, 8));
        var emp = new EmpresaDao();
        var dadosEmpresa = await emp.getDadosEmpresa(idEmpresa);
        const docRef = doc(FFAtendeFirebaseDb, "/comandas/" + idEmpresa + "/comandas", identificador);
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            const itens = collection(FFAtendeFirebaseDb, "/comandas/" + idEmpresa + "/comandas/" + identificador + "/itens");
            var itensComanda = await getDocs(itens);
            var arrayItens: Array<any> = [];
            itensComanda.docs.map((doc) => {
                arrayItens.push(
                    doc.data());
            });
            this.setState((state: any) => {
                state.comanda = docSnap.data();
                state.comanda.dados_empresa = dadosEmpresa
                state.comanda.itens_comanda = arrayItens;
                return state;
            });
        }

    }
    listaItens() {
        var componentes: Array<any> = [];
        var cont = 0;
        this.state.comanda.itens_comanda.forEach((element: any) => {
            componentes.push(
                <div key={cont} className="row lineItensComanda">
                    <div className="col-3 flex-column justify-content-center d-flex">
                        <img src={element.imagem_capa} className="imageThubnail" />
                    </div>
                    <div className="col-5 flex-column justify-content-center d-flex" style={{ fontSize: 15 }}>
                        {element.quantidade + " x " + element.descricao}
                    </div>
                    <div className="col-4 flex-column justify-content-center d-flex" style={{ fontWeight: 'bold' }}>
                        {FFString.formataMoeda(element.total)}
                    </div>
                </div>
            );
            cont++;
        })

        return componentes;
    }

    notificationClick = () => {
        const identificador = FFRequest.findGetParameter('comanda') + "";
        const idEmpresa = parseInt(identificador.substring(4, 8));
        axios
            .post(Config.urlRestApi + "PushNotification/ChamarGarconAtenderComanda.php", {
                'id_empresa': idEmpresa,
                'identificador': identificador,
                'id_comanda': FFString.pad('000', this.state.comanda.id_comanda, true)
            })
            .then((response) => {
                alert("O garçon foi notificado! Em breve você será atendido!")
            }).catch((error) => {
                alert("Houve uma falha ao acionar o garçon! Verifique se o seu dispositivo está conectado a internet!")
            })
    }

    render() {
        if (this.state.comanda) {
            return (
                <div className="container-fluid h-100 d-flex flex-column">
                    <div className="row justify-content-center">
                        <img className="imageBar" src={this.state.comanda.dados_empresa.logo}></img>
                    </div>
                    <div className="row justify-content-center headerBar">
                        <div className="col-12 text-center text-white flex-column justify-content-center d-flex ">
                            Comanda {FFString.pad('000', this.state.comanda.id_comanda, true)}
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        {this.listaItens()}
                    </div>
                    <div className="row justify-content-end totalBar">
                        <div className="col-7 text-end text-white flex-column justify-content-center d-flex totalDiv" style={{ fontWeight: 'bold' }}>
                            Total {FFString.formataMoeda(this.state.comanda.total_comanda)}
                        </div>
                    </div>
                    <a role="button" onClick={this.notificationClick}>
                        <div className="actionButton">
                            <i className="fa fa-bell"></i>
                        </div>
                    </a>
                </div>);
        } else {
            return <div>Comanda nao encontrada</div>;
        }
    }

}
export default Comanda;


