import axios from "axios";
import Config from "../Config";

class CategoriaDao {
    public async getListProdutos(token: String, idCategoria: number, dados_empresa: any) {
        var dadosCategoria: any = await axios
            .post(Config.urlRestApi + "Produtos/ListarProdutoByCategoria.php", {
                'id_categoria': idCategoria,
                'cardapioDigital': true,
                'id_empresa': dados_empresa.id_empresa,
                'cnpj_empresa': dados_empresa.cnpj,
                'token': token
            })
            .catch((error) => {
                alert("Houve uma falha ao buscar os dados do produto! Verifique se o seu dispositivo está conectado a internet!")
            })
        return dadosCategoria.data;
    }

}

export default CategoriaDao;